@import 'src/theme/fonts/fonts.scss';
@import 'src/theme/color-palette/color-palette.scss';
@import 'src/theme/variables/variables.scss';

// NEW IRONRIDGE THEME
.ironridge-theme {
  // Buttons
  .btn-primary {
    box-sizing: border-box !important;
    background-color: $orange-45 !important;
    border: solid 2.5px $orange-45 !important;
    border-radius: 4px !important;
    color: $white !important;

    &:active {
      box-sizing: border-box !important;
      border-color: $orange-45 !important;
    }

    &:hover {
      background-color: $blue-50 !important;
      border: solid 2.5px $blue-50 !important;
    }

    &:focus {
      background-color: $blue-50 !important;
      border: solid 2.5px $orange-45 !important;
    }
  }

  .btn-secondary {
    background-color: $white !important;
    box-sizing: border-box !important;
    border: solid 1px $gray-40 !important;
    border-radius: 4px !important;
    color: $black-50 !important;

    &:active {
      background-color: $white !important;
      box-sizing: border-box !important;
      border: solid 1px $gray-40 !important;
    }

    &:hover {
      box-sizing: border-box !important;
      border: solid 1px $gray-60 !important;
      background-color: $white-40 !important;
    }

    &:focus {
      box-sizing: border-box !important;
      background-color: $white-40 !important;
      border: solid 2.5px $orange-50 !important;
    }

    &:disabled {
      background-color: $white !important;
      border: solid 1px $gray-20 !important;
      color: $gray-20 !important;
    }
  }

  // COMPONENTS
  .space-horizontal {
    width: 32px !important;
  }

  .space-vertical {
    height: 32px !important;

    @media only screen and (max-width: 890px) {
      height: 24px !important;
    }
  }

  .card {
    background-color: $white !important;
    border: none !important;
    border-radius: 8px !important;
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.08) !important;

    .widget-spinner-wrapper {
      border-radius: 8px;
    }
  }

  .card-header,
  .card-footer {
    background-color: $white !important;
    border: none !important;
  }

  select {
    color: $gray-20 !important;
    font-family: $font-Archivo !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    letter-spacing: 0em !important;
    text-align: left !important;
  }

  a {
    font-family: $font-Archivo !important;
  }

  // Divider
  hr {
    height: 2px;
    background-color: $gray-30;
    border: none;
  }

  .text-primary {
    color: $black-50 !important;
  }

  input:-webkit-autofill {
    -webkit-background-clip: text !important;
  }

  .minicard-text-primary {
    color: $black-50 !important;
  }

  .mini-card-text {
    color: $black-50;
  }

  .highlight {
    background-color: $yellow-30 !important;
  }

  .search-card {
    &:hover {
      cursor: pointer !important;
      border: 1px solid $gray-55 !important;
      box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.08) !important;
    }
  }

  .notification-item {
    margin-bottom: 0 !important;
  }
}
