@import 'src/theme/fonts/fonts.scss';
@import 'src/theme/color-palette/color-palette.scss';
@import 'src/theme/variables/variables.scss';

body,
html {
  margin: 0;
  padding: 0;
  font-family: $font-Archivo;
  min-height: 100%;
  min-width: 100%;
}

.container-fluid {
  margin: 0px !important;
  padding: 0px !important;
}

// Horizontal space
// NOTE*** For horizontal space we are using div by adding class
.horizontal-space-8 {
  width: 8px !important;
}

.horizontal-space-16 {
  width: 16px !important;
}

.horizontal-space-24 {
  width: 24px !important;
}

.horizontal-space-40 {
  width: 40px !important;
}

// Vertical space
// NOTE*** For vertical space we are adding class on element
.vertical-space-8 {
  margin-top: 8px !important;
}

.vertical-space-16 {
  margin-top: 16px !important;
}

.vertical-space-20 {
  margin-top: 20px !important;
}

.vertical-space-24 {
  margin-top: 24px;
}

.vertical-space-30 {
  margin-top: 30px !important;
}

.vertical-space-32 {
  margin-top: 32px !important;
}

.vertical-space-36 {
  margin-top: 36px !important;
}

.vertical-space-40 {
  margin-top: 40px !important;
}

.vertical-space-48 {
  margin-top: 48px !important;
}

.vertical-space-56 {
  margin-top: 56px !important;
}

.vertical-space-64 {
  margin-top: 64px !important;
}

.vertical-space-95 {
  margin-top: 95px !important;
}

.p-56 {
  padding-top: 56px !important;
}

.ml8 {
  margin-left: 8px !important;
}

/* Buttons */
.btn {
  min-width: 100px;
  border-radius: 4px !important;
  font-family: $font-Archivo !important;
  font-weight: 600 !important;
}

// SIZE
.fit-content-button {
  width: fit-content !important;
}

.max-width-button {
  width: 100% !important;
}

.mixed-width-button {
  width: fit-content !important;

  @media screen and (max-width: 768px) {
    width: 100% !important;
    margin-bottom: 16px !important;
  }
}

// PADDING
.large-btn-padding {
  min-height: 44px;
  padding: 8px 139px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}

.medium-btn-padding {
  min-height: 44px;
  padding: 8px 20px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}

.small-btn-padding {
  min-height: 36px;
  padding: 4px 16px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}

// Buttons
.general-green-button {
  box-sizing: border-box !important;
  background-color: $green-70 !important;
  border: solid 2.5px $green-70 !important;
  border-radius: 4px !important;
  color: $white !important;

  &:hover {
    background-color: $green-100 !important;
    border: solid 2.5px $green-100 !important;
  }

  &:focus {
    box-sizing: border-box !important;
    background-color: $green-100 !important;
    border: solid 2.5px $orange-50 !important;
    color: $white !important;
  }

  &:active {
    box-sizing: border-box !important;
    background-color: $green-70 !important;
    border: solid 2.5px $green-70 !important;
    color: $white !important;
  }

  &:disabled {
    box-sizing: border-box !important;
    background-color: $gray-30 !important;
    border: solid 2.5px $gray-30 !important;
    color: $white !important;
  }
}

.general-white-button {
  background-color: $white !important;
  border: solid 1px $gray-55 !important;
  border-radius: 4px !important;
  color: $green-100 !important;

  &:hover {
    background-color: $white-40 !important;
    border: solid 1px $gray-55 !important;
    color: $gray-70 !important;
  }

  &:focus {
    box-sizing: border-box;
    background-color: $white-60 !important;
    border: solid 2.5px $orange-50 !important;
    color: $gray-70 !important;
  }

  &:active {
    background-color: $white !important;
    border: solid 1px $gray-55 !important;
    color: $green-100 !important;
  }

  &:disabled {
    background-color: $white !important;
    border: solid 1px $gray-30 !important;
    color: $gray-55 !important;
  }
}

.btn-ghost {
  background-color: $white !important;
  border-color: $white !important;
  color: $green-100 !important;

  &:hover {
    background-color: $white-60 !important;
    border-color: $white-60 !important;
    color: $green-100 !important;
  }

  &:focus {
    box-sizing: border-box;
    background-color: $white !important;
    border-color: $orange-50 !important;
    color: $green-100 !important;
  }
}

.back-button {
  color: $gray-70 !important;
  width: 180px !important;
}

/* FONTS */

.general-text-color {
  color: $black-50 !important;
}

.general-hr-color {
  border-color: $white-50 !important;
  border-style: solid !important;
  border-width: 1px !important;
}

.sticky-element {
  position: sticky !important;
}

.fixed-element {
  position: fixed !important;
  left: 0;
  top: 0;
  right: 0;
  height: 20px;
}

input {
  color: $green-100 !important;

  &:focus {
    box-shadow: none !important;
    color: $green-100 !important;
  }
}

// * Fonts *
h1 {
  font-family: $font-Archivo;
  font-size: 28px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0;
}

h2 {
  font-family: $font-Archivo;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0;
}

h3 {
  font-family: $font-Archivo;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0;
}

h4 {
  font-family: $font-Archivo;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0;
}

p,
label,
input,
select,
textarea,
button,
li,
a {
  font-family: $font-Archivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
}

h1.general-h1-color {
  color: $green-100;
}

.general-select-text-color {
  color: $green-100;
}

p.general-p-text-color {
  color: $green-100;
}

header {
  position: sticky;
  top: 0;
  z-index: 1000;
  width: 100%;
}

.header {
  display: grid;
  height: 56px;
  padding: 0 16px;
  border-bottom: solid 1px $gray-30;

  @media (min-width: $width-md) {
    padding: 0 18px;
  }

  img {
    position: relative;
    max-height: 34px;
  }
}

.link {
  text-decoration: underline !important;
  font-family: $font-Archivo !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  line-height: 16px !important;
  color: $green-50 !important;
}

// * Alerts *
.error-alert {
  background-color: $transparent !important;
  color: $red-50 !important;
  padding: 0px !important;
  margin-top: 8px !important;
  font-family: $font-Archivo !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  border-radius: 4px !important;
  border: none !important;
}

// * Survey
.survey-card {
  h4 {
    font-size: 14px !important;
    font-weight: 400 !important;
    color: $gray-90 !important;

    .info-tooltip {
      margin-left: 8px !important;
    }
  }

  label {
    color: $gray-70 !important;
  }

  input {
    color: $green-100 !important;

    &:focus,
    &:active {
      box-shadow: none !important;
      background-color: $white !important;
      border-color: $gray-70 !important;
    }

    &::placeholder {
      font-family: $font-Archivo !important;
      font-size: 14px !important;
      font-weight: 400 !important;
      line-height: 24px !important;
      color: $gray-55 !important;
    }
  }
}

// * Checkbox
.custom-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  cursor: pointer;
  width: 15px;
  height: 15px;
  background: transparent;
  border: 1.5px solid $gray-70 !important;
  border-radius: 3px;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('/assets/icons/check-icon.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0;
    transition: opacity 0.3s;
    pointer-events: none;
  }

  &:checked:after {
    background-color: $green-70;
    opacity: 1;
    background-size: calc(100% - 2px);
  }
}

.dropdown-menu {
  margin-top: 5px !important;
  border: none;
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.16);
  padding-bottom: 0px;

  .account-details,
  .user-company-details {
    padding: 20px 22px 10px 24px;
  }

  .account-details {
    .account-name {
      margin-bottom: 8px;
      font-family: $font-F37Incise !important;
      font-weight: 500 !important;
      font-size: 14px !important;
      line-height: 24px !important;
      text-transform: uppercase;
      color: $gray-70;
    }

    .account-full-name {
      margin-bottom: 0;
      font-weight: 700 !important;
      font-size: 16px !important;
      line-height: 24px !important;
      color: $green-100;
    }

    .user-email {
      margin-bottom: 16px;
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 24px !important;
      color: $gray-70;

      // handle long text
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .user-company-details {
    .company-name {
      margin-bottom: 8px;
      font-family: $font-F37Incise !important;
      font-weight: 500 !important;
      font-size: 14px !important;
      line-height: 24px !important;
      text-transform: uppercase;
      color: $gray-70;
    }

    .details {
      margin-bottom: 0;
      font-weight: 700 !important;
      font-size: 16px !important;
      line-height: 24px !important;
      color: $green-100;
    }

    button {
      margin-top: 16px;
      margin-bottom: 20px;
      background-color: $white;
      border-radius: 4px;
      padding: 4px 16px;
      font-size: 14px;
      font-weight: 600;
      border: 1px solid $gray-30;

      &:hover {
        cursor: pointer;
      }
    }
  }

  hr {
    height: 1px;
    background-color: $gray-30;
    border: none;
  }

  .logout {
    display: flex;
    flex-direction: row;
    padding-left: 24px;
    padding-top: 20px;
    padding-bottom: 10px;
    cursor: pointer;

    .logout-label {
      margin-left: 12px;
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
      color: $gray-70;
    }
  }

  .privacy-terms {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 24px;
    background-color: $white-30;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    .vertical-divider {
      height: 14px;
      width: 1px;
      margin-left: 14px;
      margin-right: 14px;
      background-color: $gray-30;
    }

    a {
      margin-bottom: 0 !important;
      font-size: 12px !important;
      font-weight: 400 !important;
      line-height: 16px !important;
      color: $gray-70;
      text-decoration: none;
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .demo-addones {
    margin-bottom: 20px;

    .theme-switch {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 24px 20px 10px 24px;

      .switch {
        display: flex;
        flex-direction: row;
      }
    }

    .logout {
      margin-top: 20px;
      padding-left: 24px;

      button {
        background-color: $white;
        border-radius: 4px;
        padding: 4px 16px;
        font-size: 14px;
        font-weight: 600;
        border: 1px solid $gray-30;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

.info-alert {
  background: $white-30 !important;
  padding: 1rem !important;
  margin-top: 0.5rem !important;
  font-family: $font-Archivo !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  color: $green-100 !important;
  border-radius: 4px !important;
  border: solid 1px $white-30 !important;
}

.indigo-bg-color {
  background-color: $blue-90;
}

.dark-green-bg-color {
  background-color: $green-75;
}

.transparent-bg-color {
  background-color: transparent;
}

.white-bg-color {
  background-color: $white;
}

.custom-popup {
  &.dropdown-menu {
    margin: 0 !important;
    padding: 0 !important;
    width: inherit;
    max-height: 320px;
    overflow-y: auto;

    &:focus {
      border: 1px solid $gray-70;
    }
  }

  > .dropdown-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px !important;
    border-bottom: 1px solid $white-60 !important;
    color: $green-100 !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 24px !important;
    padding: 12px 16px !important;

    &.active {
      background-color: $blue-20 !important;
    }
  }
}
