@import 'src/theme/overrides/bootstrap-overrides';
@import 'src/theme/color-palette/color-palette.scss';
@import 'src/theme/fonts/fonts.scss';
@import 'src/theme/themes/enstall-theme.scss';
@import 'src/theme/themes/enstall-eu-theme.scss';
@import 'src/theme/themes/ironridge-theme.scss';
@import 'src/theme/themes/esdec-theme.scss';
@import 'src/theme/themes/general-theme.scss';
@import '/node_modules/ngx-toastr/toastr.css';

$width-sm: 350px;
$width-md: 736px;
$width-lg: 1424px;

html,
body {
  margin: 0px;
  padding: 0px;
  font-family: $font-Archivo;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0;
}

.pointer,
a,
i {
  cursor: pointer;
}

.tooltip-arrow {
  margin: 0 0.75rem !important;
}

::-webkit-scrollbar {
  width: 10px;
  scroll-behavior: smooth;
}

// Track
::-webkit-scrollbar-track {
  background: $gray-80;
}

// Handle
::-webkit-scrollbar-thumb {
  background: white;
  cursor: pointer !important;
}

// Handle on hover
::-webkit-scrollbar-thumb:hover {
  background: white;
  cursor: pointer !important;
}

.ellipsis {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.toastr-manage-acc-custom-class {
  position: fixed !important; // need important because we want to override class from node_module toast message library
  width: 100% !important;
  left: calc(100% - 278px) !important;
  bottom: 16px !important;
  padding: 0 !important;

  > div {
    text-align: center !important;
    right: 38px !important;
  }

  @media (min-width: $width-md) {
    bottom: 32px !important;
    left: calc(100% - 280px) !important;

    div {
      right: 38px !important;
    }
  }

  .custom-toast {
    box-shadow: 0px 8px 20px 0px $black-20;
  }
}

.modal-custom-size {
  display: flex;
  width: auto;
  place-self: center;
  box-shadow: 0px 8px 24px 0px $black-10;

  @media (min-width: $width-md) {
    width: 384px;
    display: block;
  }
}
