@font-face {
  font-family: 'Archivo';
  src: url('Archivo-Regular.ttf');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'F37 Incise';
  src: url('F37Incise-Regular.ttf');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
