/*********************
 ** CONTAINER WIDTH **
 *********************/
$width-sm: 480px;
$width-md: 768px;
$width-lg: 1024px;
$width-xl: 1281px;
$width-xxl: 1400px;

/***********
 ** FONTS **
 ***********/

$font-Archivo: 'Archivo', sans-serif;
$font-F37Incise: 'F37 Incise', sans-serif;
