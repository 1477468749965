@import 'src/theme/fonts/fonts.scss';
@import 'src/theme/color-palette/color-palette.scss';

.esdec-theme {
  // Buttons
  .btn-primary {
    box-sizing: border-box !important;
    background-color: $orange-60 !important;
    border: solid 2.5px $white !important;
    border-radius: 6px !important;
    color: white !important;
    height: 44px !important;

    // TBD
    &:hover {
      background-color: $orange-70 !important;
      border: solid 2.5px $orange-70 !important;
      color: $white !important;
    }

    // TBD
    &:focus {
      box-sizing: border-box !important;
      background-color: $orange-70 !important;
      border: solid 2.5px $orange-50 !important;
      color: $white !important;
    }

    &:active {
      box-sizing: border-box !important;
      background-color: $orange-60 !important;
      border: solid 2.5px $white !important;
      color: $white !important;
    }

    // TBD
    &:disabled {
      box-sizing: border-box !important;
      background-color: $gray-30 !important;
      border: solid 2.5px $gray-30 !important;
      color: $green-100 !important;
    }
  }

  .btn-secondary {
    background-color: $transparent !important;
    border: solid 1px $gray-35 !important;
    border-radius: 4px !important;
    box-sizing: border-box !important;
    color: $blue-80 !important;

    &:active {
      background-color: $transparent !important;
      border: solid 1px $gray-35 !important;
      box-sizing: border-box !important;
      color: $blue-80 !important;
    }

    // TBD
    &:hover {
      box-sizing: border-box !important;
      border: solid 1px $green-55 !important;
      background-color: $green-55 !important;
      color: $white !important;
    }

    // TBD
    &:focus {
      box-sizing: border-box !important;
      background-color: $green-55 !important;
      border: solid 2.5px $orange-50 !important;
    }

    // TBD
    &:disabled {
      background-color: $transparent !important;
      border: solid 1px $green-40 !important;
      color: $green-40 !important;
    }
  }
}
