@import 'src/theme/variables/variables.scss';

/******************************
 ** CUSTOMIZE BOOTSTRAP GRID **
 ******************************/
$grid-breakpoints: (
  xs: 0,
  sm: $width-sm,
  md: $width-md,
  lg: $width-lg,
  xl: $width-xl,
  xxl: $width-xxl,
);

$container-max-widths: (
  sm: 460px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
);

@import '../../../node_modules/bootstrap/scss/bootstrap';
