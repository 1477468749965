@import 'src/theme/fonts/fonts.scss';
@import 'src/theme/color-palette/color-palette.scss';
@import 'src/theme/variables/variables.scss';

.enstall-theme {
  // Buttons
  .btn-primary {
    box-sizing: border-box !important;
    background-color: $white !important;
    border: solid 2.5px $white !important;
    border-radius: 4px !important;
    color: $green-70 !important;

    &:hover {
      background-color: $green-10 !important;
      border: solid 2.5px $green-10 !important;
      color: $green-100 !important;
    }

    &:focus {
      box-sizing: border-box !important;
      background-color: $green-10 !important;
      border: solid 2.5px $orange-50 !important;
      color: $green-100 !important;
    }

    &:active {
      box-sizing: border-box !important;
      background-color: $white !important;
      border: solid 2.5px $white !important;
      color: $green-70 !important;
    }

    &:disabled {
      box-sizing: border-box !important;
      background-color: $gray-40 !important;
      border: solid 2.5px $gray-40 !important;
      color: $green-100 !important;
    }
  }

  .btn-secondary {
    background-color: $transparent !important;
    border: solid 1px $green-40 !important;
    border-radius: 4px !important;
    box-sizing: border-box !important;
    color: $white !important;

    &:active {
      background-color: $transparent !important;
      border: solid 1px $green-40 !important;
      box-sizing: border-box !important;
      color: $white !important;
    }

    &:hover {
      box-sizing: border-box !important;
      border: solid 1px $green-55 !important;
      background-color: $green-55 !important;
      color: $white !important;
    }

    &:focus {
      box-sizing: border-box !important;
      background-color: $green-55 !important;
      border: solid 2.5px $orange-50 !important;
    }

    &:disabled {
      background-color: $transparent !important;
      border: solid 1px $green-40 !important;
      color: $green-40 !important;
    }
  }

  .card {
    margin: 20px auto;
    background-color: $green-40 !important;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.18);
    color: $white !important;
  }

  .card-header,
  .card-footer {
    border: none !important;
  }

  html,
  body,
  h1,
  h2,
  h3,
  h4,
  p,
  label,
  input,
  select,
  textarea {
    font-family: $font-Archivo !important;
  }

  a {
    font-family: $font-Archivo !important;
  }

  // Divider
  hr {
    height: 2px;
    background-color: $gray-30;
    border: none;
  }

  .text-primary {
    color: $green-70 !important;
  }

  select {
    color: $white;
    font-family: $font-Archivo !important;
    font-size: 14px !important;
    font-weight: 400;
    line-height: 24px !important;
    letter-spacing: 0em !important;
    text-align: left !important;
  }

  select:-webkit-autofill,
  input:-webkit-autofill {
    -webkit-background-clip: text !important;
  }

  .minicard-text-primary {
    color: $white !important;
  }

  .mini-card-text {
    color: $green-10 !important;
  }

  .home-card {
    background-color: $gray-30 !important;
  }

  .home-card-toolset {
    background-color: $gray-30 !important;
  }

  .card {
    background-color: $white !important;
  }

  .highlight {
    background-color: $yellow-30 !important;
  }

  .search-card {
    &:hover {
      cursor: pointer !important;
      border: 1px solid $gray-55 !important;
      box-shadow: 0px 0px 24px 0px rgba(255, 255, 255, 0.8) !important;
    }
  }

  // Manage account - user details
  .account-details {
    h1 {
      margin-bottom: 32px;
      color: $green-100 !important;
    }

    .space {
      margin-top: 24px !important;
    }

    .image-details {
      margin-top: 24px;
      margin-bottom: 32px;

      h4 {
        margin-bottom: 24px;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: $green-100 !important;
      }
    }

    .information-details {
      margin-top: 24px;
      margin-bottom: 32px;

      h4 {
        margin-bottom: 24px;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: $green-100 !important;
      }

      .information-form {
        margin-top: 24px;

        label {
          color: $gray-70 !important;
        }

        .lock-icon {
          position: relative;
          right: 30px;
          bottom: 6px;
        }

        .user-name {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .space-horizontal {
            width: 16px;
          }

          .field {
            flex: 1;
          }
        }

        .phone-number {
          input {
            color: $green-100 !important;
          }
        }
      }
    }

    .location-details {
      margin-top: 24px;

      h4 {
        margin-bottom: 24px;
        font-family: $font-Archivo;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: $gray-90 !important;
      }

      .location-form {
        label {
          color: $gray-70 !important;
        }

        input {
          color: $gray-90 !important;
        }

        .btn-primary {
          height: 40px !important;
          margin-top: 40px;
          background-color: $green-70 !important;
          border-radius: 4px;
          padding: 4px 16px;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          color: $white !important;
          border: none;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }

  .notification-item {
    margin-bottom: 0 !important;
  }
}
