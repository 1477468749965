// General color palette for the project

// Transparent
$transparent: transparent;

$chapter-bg-color: #f3f6f9;

// White
$white: #ffffff;
$white-20: #ffffffcc;
$white-30: #f9fafb;
$white-40: #eeeeee;
$white-50: #cccccc;
$white-60: #eaeef1;
$white-70: #e6f4f2;
$white-80: #d0d2e0;
$white-90: #ffffff80;

// Gray
$gray-5: #f0f3f5;
$gray-10: #f1f1f1;
$gray-15: #f4f4f4;
$gray-20: #e2e2e2;
$gray-25: #c2c9d0;
$gray-30: #c9cfd5;
$gray-35: #d6d6d6;
$gray-40: #a0a0a0;
$gray-50: #cacaca;
$gray-55: #96a1a8;
$gray-60: #6e6e6e;
$gray-70: #5d6971;
$gray-80: #858585;
$gray-85: #565656;
$gray-90: #303a41;
// Black

$black-10: #00000014;
$black-15: #00000026;
$black-20: #00000029;
$black-50: #333333;
$black-70: #000000b2;
$black-100: #000000;

// Yellow
$yellow-10: #ddef30;
$yellow-30: #fffec3;

// Orange
$orange-20: #f2a251;
$orange-30: #f3af00;
$orange-40: #f6941c;
$orange-45: #f7941d;
$orange-50: #ff8017;
$orange-60: #f47503;
$orange-70: #aa5c16;

// Red
$red-10: #e9b7ba;
$red-50: #b70e19;
$red-70: #81040c;

// Green
$green-10: #b3c6c2;
$green-15: #809f99;
$green-20: #27ae60;
$green-30: #28a745;
$green-40: #4d7970;
$green-50: #007e6f;
$green-55: #004f45;
$green-60: #165f43;
$green-65: #0f4b3f;
$green-70: #004033;
$green-75: #024233;
$green-80: #002f29;
$green-90: #00211a;
$green-100: #031a15;

$footer-green: #014131;
$toast-border-green: #68c690;

// Blue
$blue-10: #64e2d3;
$blue-20: #f5f5f5;
$blue-50: #3479b6;
$blue-60: #2073c0;
$blue-65: #1563aa;
$blue-70: #0060b2;
$blue-80: #05092e;
$blue-90: #151c64;
$blue-95: #131a65;

// Input states colors
$input-disabled-color: $gray-55;
$input-text-color: $gray-90;
$focus-border: 2.5px solid $orange-50;
